// for App
export const MUSICNERD_PAGE_VISIT = "MUSICNERD_PAGE_VISIT"

// for HomeScreen
export const MUSICNERD_ARTIST_SELECTED = "MUSICNERD_ARTIST_SELECTED"

// for Leaderboard
export const MUSICNERD_PLAYAGAIN = "MUSICNERD_PLAYAGAIN"
export const MUSICNERD_AFTER_SIGNUP = "MUSICNERD_AFTER_SIGNUP"
export const MUSICNERD_SHARE = "MUSICNERD_SHARE"

// for profile
export const MUSICNERD_DELETE_ACCOUNT_CLICKED = "MUSICNERD_DELETE_ACCOUNT_CLICKED"

// for ScoreScreen
export const MUSICNERD_START_OVER = "MUSICNERD_START_OVER"
export const MUSICNERD_ARTIST_QUIZ_RESPONDED = "MUSICNERD_ARTIST_QUIZ_RESPONDED"
export const MUSICNERD_WEBPOPUP_2 = "MUSICNERD_WEBPOPUP_2"
export const MUSICNERD_ACCOUNT_CREATED = "MUSICNERD_ACCOUNT_CREATED"
export const MUSICNERD_SIGNUP = "MUSICNERD_SIGNUP"

// for Song 
export const MUSICNERD_ARTIST_QUIZ_ANSWERED_ = "MUSICNERD_ARTIST_QUIZ_ANSWERED_"
export const MUSICNERD_ARTIST_QUIZ_STARTED = "MUSICNERD_ARTIST_QUIZ_STARTED"
