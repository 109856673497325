import CryptoJS from "crypto-js";
import { postTikTokEventsAPIEvent } from "../endpoints/api"

const generateEventId = () => {
    return 'event_' + new Date().getTime() + '_' + Math.floor(Math.random() * 1000);
};

const getHashedValue = (value: string) => {
    return CryptoJS.SHA256(value.toLowerCase().trim()).toString()
} 

const getCookie = (name: string): string | undefined => {
    let cookie: Record<string, string> = {};
    document.cookie.split(';').forEach(function(el) {
      let [k,v] = el.split('=');
      cookie[k.trim()] = v;
    })
    return cookie[name];
}

const trackTikTokPixelEvent = (eventName: string, eventId: string, hashedEmail: string, hashedUuid: string, eventContent: any) => {
    if (window.ttq) {
        window.ttq.identify({
            "email": hashedEmail,
            "external_id": hashedUuid
        });

        window.ttq.track(eventName, {
            "contents": [eventContent]
        }, {
            "event_id": eventId
        });
        // console.log('Tiktok Pixel event tracked:', { eventName, eventContent, eventId });
        console.log('Tiktok Pixel event tracked:');
    } else {
        console.warn('Tiktok Pixel not initialized.');
    }
};

export const trackTikTokEvent = async (eventName: string, email: string, uuid: string, eventContent: any) => {
    // console.log("tracking tiktok", eventName, email, uuid, eventContent)
    if (!email || !uuid) {
        console.log("Skipping tiktok pixel. No email or uuid.")
        return
    }
    
    const eventId = generateEventId() //For deduping the events sent via pixel and events API
    const eventTime = Math.floor(Date.now() / 1000)

    const hashedEmail = getHashedValue(email)
    const hashedExternalId = getHashedValue(uuid)
    const userAgent = navigator.userAgent

    const tiktokClickId = getCookie('ttclid')
    const tiktokCookie = getCookie('_ttp')

    const userData = {
        email: hashedEmail,
        external_id: hashedExternalId,
        user_agent: userAgent,
        ttclid: tiktokClickId ? tiktokClickId : '',
        ttp: tiktokCookie ? tiktokCookie : ''
    }

    const eventData = {
        event: eventName,
        event_time: eventTime,
        event_id: eventId,
        user: userData,
        properties: {
            contents: [eventContent]
        },
    }
     
    try {
        const eventsApiBody = {
            eventData
        }
        if (email) {
            const postConversionApiResponse = await postTikTokEventsAPIEvent(eventsApiBody)
            // console.log("tiktok events api posted", postConversionApiResponse.data)
            console.log("tiktok events api posted");
        } else {
            console.log("skipping tiktok events api call. No email.")
        }
        
    } catch (error) {
        console.log("couldn't post tiktok events api event", error)
    }
    if (email) {
        trackTikTokPixelEvent(eventName, eventId, hashedEmail, hashedExternalId, eventContent)
    }
};
