export const ASSET_MONEY_TITLE = 'Asset Money';
export const CLEVERTAP_ACCOUNT_ID = 'RZK-9RW-686Z';

export type META_EVENT_TYPE = "track" | "trackCustom"

export const META_EVENTS = {
    "QUIZ_STARTED": "QuizStarts",
    "ARTIST_SELECTED": "SelectArtist",
    "ACCOUNT_CREATED_NEW_SIGNUP": "CompleteRegistration",
    "QUIZ_SHARE_SCORE": "ShareScore",
    "QUIZ_COMPLETED": "CompleteQuiz",
}

export const TIKTOK_EVENTS = {
    "QUIZ_STARTED": "QuizStarts",
    "ARTIST_SELECTED": "SelectArtist",
    "ACCOUNT_CREATED_NEW_SIGNUP": "CompleteRegistration",
    "QUIZ_SHARE_SCORE": "ShareScore",
    "QUIZ_COMPLETED": "CompleteQuiz",
}
