import { useEffect } from "react";
import HomePage from "./screens/Homepage";
import { initializeApp } from "firebase/app";
import "./index.css";
import { Route, Routes } from "react-router-dom";
import Artist from "./screens/artist";
import AudioPlayer from "./screens/song";
import LeaderboardTable from "./screens/Leaderboard/Leaderboard";
import ArtistLeaderboard from "./screens/artistLeaderboard";
import ScoreScreen from "./screens/ScoreScreen";
import Artists from './screens/Artists.jsx'
import Profile from "./screens/Profile";
import useClevertap from './hooks/useClevertap'
import Offers from "./screens/Offers";
import Signup from "./screens/Signup";
import { MUSICNERD_PAGE_VISIT } from "./constants/events";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
};

initializeApp(config);

function App() {
  const { handleEventPushClick } = useClevertap()

  // page visit event with Clevertap and page name/path as event property
  useEffect(() => {
    handleEventPushClick('Page Visit', { 'PageName': window.location.pathname })
    handleEventPushClick(MUSICNERD_PAGE_VISIT, { 'PageName': window.location.pathname })
  }, [])

  return (
      <Routes>
        <Route index path="/" element={<HomePage />} />
        <Route index path="/:username" element={<HomePage />} />
        {/* <Route index path="/artists" element={<Artist />} />
        <Route index path="/quiz/artists" element={<Artist />} /> */}
        <Route index path="/discover" element={<Artists />} />
        <Route index path="/quiz/discover" element={<Artists />} />
        <Route index path="/audio/:id" element={<AudioPlayer />} />
        <Route index path="/quiz/:id" element={<AudioPlayer />} />
        {/* <Route index path="/score/:right/:id/:time" element={<ScoreScreen />} /> */}
        <Route index path="/score/:id" element={<ScoreScreen />} />
        <Route index path="/quiz/score/:id" element={<ScoreScreen />} />
        <Route
          index
          path="/leaderboard/:id/:playername?"
          element={<LeaderboardTable />}
        />
        <Route
          index
          path="/quiz/leaderboard/:id/:playername?"
          element={<LeaderboardTable />}
        />
        <Route index path="/artists-leaderboard" element={<ArtistLeaderboard />} />
        <Route index path="/quiz/artists-leaderboard" element={<ArtistLeaderboard />} />
        <Route index path="/offers" element={<Offers />} />
        <Route index path="/quiz/offers" element={<Offers />} />
        <Route index path="/profile" element={<Profile />} />
        <Route index path="/quiz/profile" element={<Profile />} />
        <Route index path="/signup" element={<Signup />} />
        {/* <Route path="*" element={<NotFound />} /> */}
      </Routes>
  );
}

export default App;
