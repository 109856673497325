import { META_EVENT_TYPE } from "../constants/dataConstant";
import CryptoJS from "crypto-js";
import { postMetaConversionsAPIEvent } from "../endpoints/api"

const generateEventId = () => {
    return 'event_' + new Date().getTime() + '_' + Math.floor(Math.random() * 1000);
};

const getHashedValue = (value: string) => {
    return CryptoJS.SHA256(value.toLowerCase().trim()).toString()
} 

const getCookieValue = (name: string): string | undefined => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift();
};

const trackPixelEvent = (trackType: META_EVENT_TYPE, eventName: string, eventId: string, userData: any, customData: any) => {
    if (window.fbq) {
        let user_data = null
        
        if (userData && userData.email) {
            const hashedEmail = getHashedValue(userData.email)
            user_data = {
                em: hashedEmail
            }
        }

        let eventData = {
            ...customData
        }
        if (user_data) {
            eventData.user_data = user_data
        }
        
        window.fbq(trackType, eventName, eventData, { eventID: eventId });
        // console.log('Pixel event tracked:', { eventName, eventData, eventId });
        console.log('Pixel event tracked:');
    } else {
        console.warn('Facebook Pixel not initialized.');
    }
};

export const trackMetaEvent = async (trackType: META_EVENT_TYPE, eventName: string, email: string, customData: any) => {
    const eventId = generateEventId() //For deduping the events sent via pixel and conversios API
    const eventTime = Math.floor(Date.now() / 1000)
    const fbp = getCookieValue('_fbp');
    const fbc = getCookieValue('_fbc');
    let userData = {}
    if (email) {
        userData = {
            email: email,
            fbp: fbp || '',
            fbc: fbc || ''
        }
    }
     
    try {
        const conversionApiBody = {
            eventName, eventTime, eventId, userData, customData
        }
        if (email) {
            const postConversionApiResponse = await postMetaConversionsAPIEvent(conversionApiBody)
            // console.log("conversion api posted", postConversionApiResponse.data)
            console.log("conversion api posted");
        } else {
            console.log("skipping conversion api call. No email.")
        }
        
    } catch (error) {
        console.log("couldn't post meta conversion api event", error)
    }
    trackPixelEvent(trackType, eventName, eventId, userData, customData)
};
